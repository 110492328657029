.signUp-form {
    display: flex;
    flex-direction: column;
    background-color: transparent;

    h2 {
        margin-bottom: $space_x4;
        text-transform: uppercase;
        font-weight: $medium;
        color: $color-primario;
        @include font-size(18px);
    }

    .input-group {
        position: relative;
        margin-bottom: 3rem;
        height: fit-content;

        &:last-of-type {
            margin-bottom: 5rem;
        }

        $labelHeight: 24px;

        label {
            display: block;
            @include font-size(18px);
            color: $color-primario;
            height: $labelHeight;
        }

        input[type="text"],
        input[type="phone"],
        input[type="email"],
        input[type="password"],
        select,
        .react_select__control {
            color: $color-primario;
            height: 30px;
            border: 0;
            outline: 0;
            background: transparent;
            border-radius: 0;
            border-bottom: solid 1px $color-primario;
            @include font-size(16px);

            &::placeholder {
                color: get-opacity($color-primario, 1);
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
                -webkit-text-fill-color: $color-primario;
                transition: background-color 600000s 0s, color 600000s 0s;
            }

            .opt {
                color: $color-primario;
            }

            &:focus {
                border-bottom: solid 2px $color-secundario;

                &::placeholder {
                    color: get-opacity($color-secundario, 0.5);
                }
            }
        }
        .react_select__control {
            cursor: pointer;
        }
        .react_select {
            &__control {
                color: $color-primario;
                box-shadow: none;
                min-height: unset;
                &--is-focused {
                    border-bottom: solid 2px $color-secundario;
                }
            }
            &__input-container {
                margin: 0;
            }
            &__value-container {
                padding: 0;
            }
            &__single-value {
                margin-bottom: 12px;
                padding: 4px 0;
            }
            &__menu {
                margin-top: 0;
                margin-bottom: 0;
                border-radius: 0;
                z-index: 10;
                &--is-open {
                    z-index: 10;
                }
            }

            &__indicator {
                color: $color-primario;
                align-self: flex-start;
                padding: 4px 4px 0;

                svg {
                    pointer-events: none;
                }

                &-separator {
                    background-color: transparent;
                }
            }

            &__placeholder {
                font-family: $Fuente1;
                color: get-opacity($color-primario, 1);
                margin-bottom: 12px;
            }
        }

        select {
            .opt {
                color: $color-primario;
            }

            &:focus {
                color: get-opacity($color-secundario, 0.5);
                border-bottom: solid 2px $color-secundario;
            }
        }

        .error-icon {
            height: $labelHeight;
            width: $labelHeight;
            color: $color-negative;
            position: absolute;
            top: 0;
            right: 0;

            &.bottom-fixed {
                top: unset;
                bottom: $space_half;
            }
        }
        .error-msg {
            color: $color-negative;
            height: 24px;
            position: absolute;
            bottom: -28px;
            left: 0;
        }
    }

    .submit-btn {
        width: 100%;
        max-width: 400px;
        height: 50px;
        align-self: center;
    }

    //1200
    @include media(desktop-m) {
        input[type="text"],
        input[type="phone"],
        input[type="email"],
        input[type="password"],
        select,
        .react_select__control {
            @include font-size(18px);
        }
        .input-group {
            &:last-of-type {
                margin-bottom: 3rem;
            }
        }

        h2 {
            margin-bottom: 49px;
        }

        .columns {
            column-count: 2;
            width: calc((2 * 400px) + $space_x7);
            transform: translateX(15px);
            padding-bottom: 2rem;
            margin: 0 auto;

            > div {
                width: 400px;

                .r-social {
                    padding-top: 73px;
                }
            }
        }
    }

    //1780
    @include media(desktop-xl) {
        .columns {
            width: calc((2 * 400px) + $space_x15);
            transform: translateX(35px);

            > div {
                .r-social {
                    padding-top: 80px;
                }
            }
        }
    }
    @include media(desktop-xxxl) {
        .input-group {
            &:last-of-type {
                margin-bottom: 3.4rem;
            }
        }

        .columns {
            width: calc((2 * 500px) + $space_x15);

            > div {
                width: 500px;
                .r-social {
                    padding-top: 95px;
                }
            }
        }
    }
}
