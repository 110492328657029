
  
@keyframes pulse-animation {
    0% {
        transform: scale(1);
        filter: drop-shadow(0 0 0.75rem $color-secundario);
    }
    50% {
        transform: scale(1.2);
        filter: drop-shadow(0 0 7rem $color-variante-dos);
    }
    100% {
        transform: scale(1);
        filter: drop-shadow(0 0 0.75rem $color-secundario);
    }
}
  
  
  