//Este css se aplica a SignInForm y ForgotForm

.form-tower {
    background-color: transparent;

    .input-group {
        position: relative;
        margin-bottom: 3rem;
        height: fit-content;

        &:last-of-type:not(:only-of-type) {
            // margin-bottom: 2rem;
        }

        $labelHeight: 24px;

        label {
            display: block;
            @include font-size(18px);
            color: $color-claro;
            height: $labelHeight;
        }

        input[type="text"],
        input[type="email"],
        input[type="password"] {
            @include font-size(18px);
            color: $color-claro;
            height: 3rem;
            padding-left: $space_half;
            border: 0;
            outline: 0;
            background: transparent;
            border-bottom: solid 1px $color-claro;

            &:focus {
                border-bottom: solid 2px $color-secundario;
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
                -webkit-text-fill-color: $color-claro;
                transition: background-color 600000s 0s, color 600000s 0s;
            }
        }

        .error-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            height: $labelHeight;
            width: $labelHeight;
            color: $color-negative;
            position: absolute;
            top: 0;
            right: 0;
        }
        .error-msg {
            color: $color-negative;
            height: 24px;
            position: absolute;
            bottom: -28px;
            left: 0;
        }
        .watch-pass {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $color-claro;
            position: absolute;
            bottom: 10px;
            right: 5px;
            cursor: pointer;
        }
    }

    .forgot-pass-btn {
        display: block;
        color: $color-secundario;
        transition: 200ms linear;
        margin-bottom: $space_x2;
        background-color: transparent;
        cursor: pointer;

        &:focus-visible {
            outline: solid 2px $color-claro;
            outline-offset: 1px;
        }
    }
    .back-login-btn {
        @extend .forgot-pass-btn;
        margin: $space_x2 0;
    }

    .auth-failure-msg {
        text-align: center;
        color: $color-negative;
        padding-bottom: $space_x2;
    }

    .signup-link {
        color: $color-secundario;
        margin-bottom: 2rem;

        a {
            text-decoration: underline;
        }
    }
    .submit-btn {
        width: 100%;
        height: 50px;
    }
    // 1200px
    @include media(desktop-m) {
        $colorA: get-opacity($color-primario, 0.98);

        .input-group {
            input[type="text"],
            input[type="email"],
            input[type="password"] {
                &:-webkit-autofill,
                &:-webkit-autofill:hover,
                &:-webkit-autofill:focus,
                &:-webkit-autofill:active {
                    -webkit-text-fill-color: $color-claro;
                    transition: background-color 600000s 0s, color 600000s 0s;
                }
            }
        }
    }
}
