.prehome-login {
    &__section {
        padding: $space_x6 $space_x4;
        position: relative;

        .title {
            width: fit-content;
            color: $color-claro;
            margin-bottom: $space_x5;
            transition: 500ms linear;
            transition-delay: 200ms;
            transform: translateX(-10%);
            opacity: 0;
            @include font-size(32px);
            line-height: 35px;

            strong {
                color: $color-secundario;
            }
            &.appear {
                transform: translateX(0);
                opacity: 1;
            }
        }
    }

    &__section {
        display: grid;
        place-items: center;
        background-color: $color-primario;

        .logo {
            display: none;
        }
    }
    &__container {
        width: 100%;
        max-width: 400px;
    }

    //768
    @include media(tablet) {
        &__section {
            padding: $space_x15 $space_x11;
        }
    }

    //1200
    @include media(desktop-m) {
        &__container {
            width: 30%;
            max-width: 400px;
            display: flex;
            flex-direction: column;
        }

        &__section {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding: $space_x6 $space_x8 200px;
            background-image: url(../../images/tower_icon.png);
            background-size: 34vw 34vw;
            background-position: right bottom;
            background-repeat: no-repeat;
            @include image-2x("../../images/tower_icon@2x.png", 34vw 34vw);
            @include image-3x("../../images/tower_icon@2x.png", 34vw 34vw);
            background-blend-mode: multiply;

            .logo {
                display: block;
                width: 180px;
                max-width: 180px;
                margin-bottom: $space_x11;
                align-self: center;

                img {
                    width: 100%;
                }
            }
            .title {
                font-weight: 300;
                align-self: center;
                @include font-size(36px);
                line-height: 38px;

                strong {
                    font-weight: 500;
                }
            }
        }
    }

    @include media(desktop-l) {
        &__section {
            padding: $space_x12 $space_x8 300px;
        }
    }

    //1780
    @include media(desktop-xl) {
        &__section {
            .logo {
                width: 220px;
                max-width: 220px;
                margin-bottom: $space_x14;
            }
        }
    }
}
