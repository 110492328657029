
.socials {
    display: flex;
    justify-content: space-between;
    
    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 29px;
        max-height: 29px;
        padding: $space_half;
        transition: 100ms transform ease-in-out;
        cursor: pointer;

        &:hover {
            transform: scale(1.25);
        }
        svg {
            transform: scale(.85);
        }
    }
}